import styled from "styled-components"
import { colors } from "../../assets/contants/colors"

export const StyledPhoneRegistration = styled.section`
    min-height: 295px;
    background: ${colors.blue};
    padding-top: 62px;
    padding-bottom: 62px;
`

export const Heading = styled.h2`
    font-size: 36px;
    text-align: center;
`

export const Phone = styled.a`
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
    text-decoration: none;
    color: ${colors.default};
    display: block;
    text-align: center;
   
`

export const Details = styled.p`
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
`