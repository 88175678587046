import React, { useContext, useEffect, useRef } from "react"
import {
  StyledLocation,
  StyledSectionTitle,
  LocationDescription,
  MapWrapper,
  LocationWrapper,
  Address
} from "./Location.styles"
import { ContentfulContext } from "../../contexts/ContentfulContext"

import Animations from "../../helpers/animations";

export const Location = () => {
  const context = useContext(ContentfulContext)
  const map = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const animations = new Animations();
      animations.animateRight('map')
    }
  }, [])

  return (
    <StyledLocation className="container" id="location">
      <LocationWrapper>
        <div className="row">

          <div className="col-md-6 col-sm-12">
            <StyledSectionTitle title="Adres i lokalizacja"/>
            <LocationDescription>
              <strong>
                Specjalistyczny Gabinet Okulistyczny
                lek. med. Agnieszka Skalska-Dziobek
              </strong>
              {context && context.contact &&
              <Address dangerouslySetInnerHTML={{ __html: context.contact.address }}></Address>}
            </LocationDescription>
          </div>

          <div className="col-md-6 col-sm-12 map">
              <MapWrapper>
                {context && context.dataLoaded &&
                  <div ref={map}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.894401955616!2d20.984261516334602!3d50.013334426644285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDAwJzQ4LjAiTiAyMMKwNTknMTEuMiJF!5e0!3m2!1spl!2spl!4v1587320872861!5m2!1spl!2spl"
                      width="580" height="340" frameBorder="0" style={{ border: "0" }} allowFullScreen="" aria-hidden="false"
                      tabIndex="0"></iframe>
                  </div>
                }
              </MapWrapper>
          </div>
        </div>
      </LocationWrapper>
    </StyledLocation>
  )
}

export default Location