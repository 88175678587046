import React, { useContext, useEffect } from "react"
import { StyledNews, StyledSectionTitle, FacebookPagePlugin } from "./News.styles"
import Loader from "../../components/Loader/Loader"
import { ContentfulContext } from "../../contexts/ContentfulContext"

// import Helmet from "react-helmet"

export const News = () => {
  const context = useContext(ContentfulContext)
  let isMobileResolution = false

  if (typeof window !== "undefined") {
    isMobileResolution = window.screen.width <= 768
  }

  return (
    <StyledNews className="container" id="news">
      <StyledSectionTitle title="Aktualności"/>
      {context && context.dataLoaded &&
      <FacebookPagePlugin>
        <iframe
          src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAgnieszka-Skalska-Dziobek-Specjalistyczny-Gabinet-Okulistyczny-104220874579382%2F%3Fepa%3DSEARCH_BOX&tabs=timeline&width=${isMobileResolution ? 340 : 500}&height=${isMobileResolution ? 459 : 403}&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId`}
          width={isMobileResolution ? 340 : 500} height={isMobileResolution ? 459 : 403}
          style={{ border: "none", overflow: "hidden" }} scrolling="no" frameBorder="0"
          allowTransparency="true" allow="encrypted-media">
        </iframe>
      </FacebookPagePlugin>
      }

    </StyledNews>
  )
}

export default News