import React, { createContext } from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Header from "../sections/Header/Header"
import News from "../sections/News/News"
import Offer from "../sections/Offer/Offer"
import OfferDescription from "../sections/OfferDescription/OfferDescription"
import Pricing from "../sections/Pricing/Pricing"
import OnlineRegistration from "../sections/OnlineRegistration/OnlineRegistration"
import Gallery from "../sections/Gallery/Gallery"
import Location from "../sections/Location/Location"
import PhoneRegistration from "../sections/PhoneRegistration/PhoneRegistration"
import { StyledNews } from "../sections/News/News.styles"
import Helmet from "react-helmet/es/Helmet"
import {ContentfulContext} from "../contexts/ContentfulContext"
import ContentfulContextProvider from "../contexts/ContentfulContext"
// import Helmet from "react-helmet/es/Helmet"

const IndexPage = () => (

  <ContentfulContextProvider value="test">
    <Layout>
      <Header/>
      <News/>
      <Offer/>
      <OfferDescription/>
      <Pricing/>
      <OnlineRegistration/>
      <Gallery/>
      <Location/>
      <PhoneRegistration/>
      {/*<SEO/>*/}
    </Layout>
  </ContentfulContextProvider>
)

export default IndexPage
