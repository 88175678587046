import React, { createContext, useEffect, useState } from "react"
import { getOffer, getPricing, getAppointmentDescription, getContactData } from "../api/Api"

export const ContentfulContext = createContext(null)

export const ContentfulContextProvider = ({ children }) => {

  const [pricing, setPricing] = useState([])
  const [offer, setOffer] = useState([])
  const [appointmentDescription, setAppointmentDescription] = useState([])
  const [contact, setContact] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)

  const providerValues = {
    pricing,
    offer,
    appointmentDescription,
    contact,
    dataLoaded
  }
  useEffect(() => {
    Promise.all([
      getPricing(),
      getOffer(),
      getAppointmentDescription(),
      getContactData()
    ]).then(([pricing, offer, appointment, contact])=> {
      setPricing(pricing)
      setOffer(offer)
      setAppointmentDescription(appointment)
      setContact(contact);
      setDataLoaded(true)
    })
  }, [])

  return (
    <ContentfulContext.Provider value={providerValues}>
      {children}
    </ContentfulContext.Provider>
  )
}

export default ContentfulContextProvider