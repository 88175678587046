import Client from "../helpers/Contentful"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

export const getPricing = () => {
  return Client.getEntries({
    content_type: "pricing"
  }).then(response => {
     let parsedResponse = response.items[0].fields.service.map(entry => {
      return {
        service: entry.fields.service,
        price: entry.fields.price,
      }
    })
    return Promise.resolve(parsedResponse);
  })
};

export const getOffer = () => {
  return Client.getEntries({
    content_type: "offer"
  }).then(response => {
    const fields = response.items[0].fields;
    const rawRichTextField = fields.forPatient;
    let forPatient = documentToHtmlString(rawRichTextField);
    let parsedResponse = {
      offer: fields.offer,
      forPatient: forPatient
    };
    return Promise.resolve(parsedResponse);
  })
};

export const getAppointmentDescription = () => {
  return Client.getEntries({
    content_type: "beforeAppointment"
  }).then(response => {
    const fields = response.items[0].fields;
    let parsedResponse = {
      adults: documentToHtmlString(fields.adults),
      children: documentToHtmlString(fields.children)
    };
    return Promise.resolve(parsedResponse);
  })
};

export const getContactData = () => {
  return Client.getEntries({
    content_type: "contact"
  }).then(response => {
    const fields = response.items[0].fields;
    let parsedResponse = {
      address: documentToHtmlString(fields.address),
      phoneNumber: fields.phoneNumber,
      registration: documentToHtmlString(fields.registration)
    };
    return Promise.resolve(parsedResponse);
  })
};