import styled from "styled-components"
import { breakpoints } from "../../assets/contants/breakpoints"
import Img from "gatsby-image"

export const StyledOfferDescription = styled.section`
    margin-top: 205px;
    margin-bottom: 100px;
    
    ul {
        font-size: 14px;
        margin-left: 40px;
        margin-top: 35px;
        list-style-type: disc;
    
        li {
          margin-bottom: 20px;
        } 
    }
`

export const ImageWrapper = styled.div`
  position: relative;
  @media (max-width: ${breakpoints.sm}) {
      margin-bottom: 30px;
  }
`

export const ImageRight = styled(Img)`
width: 100%;
`

export const ImageRightBackground = styled(Img)`
  width: 100%;
  position: absolute !important;
  right: 0;
  top: 0;
  z-index: -1;
`

export const ImageLeft = styled(Img)`
    width: 100%;
`

export const ImageLeftBackground = styled(Img)`
    width: 100%;
    position: absolute !important;
    z-index: -1;
    left: 0;
    top: 0;
`


export const Section = styled.div`
  min-height: 740px;  
`

export const OrderedList = styled.ol`
 margin-top: 43px;
 font-size: 14px;
;`
