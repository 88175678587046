import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  Title, Subtitle, StyledHeader, HeaderLeft, ImageWrapper,
  ImageOverlay, PhoneRegistration, OnlineRegistration, ImageEye,
  HeaderRight, ContactWrapper, Info
} from "./Header.styles"
import { ContentfulContext } from "../../contexts/ContentfulContext"
import { handleLinkClick } from "../../helpers/scrollToSection"
import imageOverlay from "../../assets/images/rectangle-orange-right-2.svg";
import image from "../../assets/images/rectangle-orange-right-2.svg";

export const Header = () => {
  const queryResults = useStaticQuery(query);
  const eyeImage = queryResults.eyeImage
  const eyeOverlay = queryResults.eyeOverlay
  const context = useContext(ContentfulContext);
  return (
    <StyledHeader className="container" id="header">
      <div className="row reorder-md-column">
        <HeaderLeft className="col-lg-6 col-sm-12">

          <div>
            <h1>
              <Title>Diagnostyka i leczenie chorób oczu w Tarnowie</Title>
              <Subtitle>lek. med. Agnieszka Skalska-Dziobek</Subtitle>
            </h1>
            <Info>
              <p className="mt-4">Zarejestruj się</p>
              <div className="mt-3">
                <ContactWrapper>
                  {context && context.contact &&
                    <PhoneRegistration href={'tel:' + context.contact.phoneNumber}>
                      Telefonicznie: {context.contact.phoneNumber}
                    </PhoneRegistration>
                  }
                  <span>
                    Lub
                  </span>
                  <OnlineRegistration onClick={(e) => handleLinkClick(e, "#online-registration")}>
                    Przez system online
                  </OnlineRegistration>
                </ContactWrapper>
              </div>
            </Info>
          </div>
        </HeaderLeft>
        <HeaderRight className="col-lg-6 col-sm-12">
          <ImageWrapper>
            <ImageEye fluid={eyeImage.childImageSharp.fluid} alt="eye-image"/>
            <ImageOverlay fluid={eyeOverlay.childImageSharp.fluid} alt="eye-overlay"/>
            {/*<ImageEye fluid={eyeImage.childImageSharp.fluid} alt="eye-image"/>*/}
            {/*<ImageOverlay fluid={eyeOverlay.childImageSharp.fluid} alt="eye-overlay"/>*/}
          </ImageWrapper>
        </HeaderRight>
      </div>
    </StyledHeader>
  )

}

export default Header

export const query = graphql`
    query {
        eyeImage: file(relativePath: {eq: "heading-eye2.png"}) {
            relativePath
            childImageSharp {
                fluid(maxWidth: 527) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        eyeOverlay: file(relativePath: {eq: "heading-eye3.png"}) {
            relativePath
            childImageSharp {
                fluid(maxWidth: 527) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`