import React from "react"
import sectionDecor from '../../assets/images/section-decor.svg';
import {Title} from "./SectionTitle.styles"

export const SectionTitle = ({title, className}) => {

  return (
    <Title className={className}>
      {title}
    </Title>
  )
}

export default SectionTitle