import styled from "styled-components";
import { colors } from "../../assets/contants/colors"


export const StyledPricing = styled.section`
    min-height: 638px;
    padding-top: 60px;
    padding-bottom: 95px;
    background: ${colors.blue}
`

export const Heading = styled.h2`
    font-size: 36px;
    font-weight: bold;
    text-align: center;
`

export const PricingList = styled.ul`
  padding: 0;
  li {
    display: table;
    margin-top: 35px;
    
    span {
      display: table-cell;
      
            
      & + span {
         text-align: right;
         width: 1%;
         vertical-align: bottom;
         padding-left: 0.5rem;
      }
      
      &:first-child {
        position: relative;
        overflow: hidden;
        
        &::after {
           content: "";
           position: absolute;
           bottom: 8px;
           margin-left: 0.5rem;
           width: 100%;
           border-bottom: 1px dashed #15235c;
        }
      }
    }
  }

`

export const Price = styled.span`
    white-space: nowrap;
`