import React, { useContext, useEffect, useRef } from "react"
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import {
  StyledOfferDescription,
  List,
  ImageWrapper,
  ImageRight,
  ImageRightBackground,
  Section,
  OrderedList,
  ImageLeft,
  ImageLeftBackground
} from "./OfferDescription.styles"

import ScrollMagic from "scrollmagic"
import gsap from "gsap"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
import { graphql, useStaticQuery } from "gatsby"
import { ContentfulContext } from "../../contexts/ContentfulContext"
import Animations from "../../helpers/animations"


export const OfferDescription = () => {
  const imagesRight = useRef(null)
  const rightDescription = useRef(null)
  const imagesLeft = useRef(null)

  const queryResults = useStaticQuery(query);
  const people1 = queryResults.people
  const peopleBckg1 = queryResults.peopleBckg

  const child1 = queryResults.child
  const childBckg1 = queryResults.childBckg

  const context = useContext(ContentfulContext)

  useEffect(() => {
    if(typeof window !== 'undefined') {
      ScrollMagicPluginGsap(ScrollMagic, gsap)
      const scrollController = new ScrollMagic.Controller()
      const [imageRight, backgroundImageRight] = imagesRight.current.children
      const [imageLeft, backgroundImageLeft] = imagesLeft.current.children
      gsap.set([backgroundImageRight, backgroundImageLeft], { autoAlpha: 0 })

      const tl = gsap.timeline({ defaults: { ease: "power2.EaseInOut" } })
      tl.fromTo(imageRight, {}, { x: "25", y: "-25", autoAlpha: 1, duration: 0 })
        .fromTo(backgroundImageRight, { x: "-25", y: "25" }, { duration: 0.6, x: "0", y: "0", autoAlpha: 1 }, "0.4")
        .fromTo(imageRight, { x: "25", y: "-25" }, { duration: 0.4, x: "0", y: "0" }, "-=0.4")

      const tl2 = gsap.timeline({ defaults: { ease: "power2.EaseInOut" } })
      tl2.fromTo(imageLeft, {}, { x: "-25", y: "-25", autoAlpha: 1 , duration: 0},)
        .fromTo(backgroundImageLeft, { x: "25", y: "25" }, { duration: 0.4, x: "0", y: "0", autoAlpha: 1 }, "0.4")
        .fromTo(imageLeft, {}, { duration: 0.4, x: "0", y: "0" }, "-=0.4")

      new ScrollMagic.Scene({
        triggerElement: imageRight,
        triggerHook: 0.4,
        reverse: false
      })
        .setTween(tl)
        .addTo(scrollController);

      new ScrollMagic.Scene({
        triggerElement: imageLeft,
        triggerHook: 0.4,
        reverse: false
      })
        .setTween(tl2)
        .addTo(scrollController);

      const animations = new Animations();
      animations.animateRight('animate-right');
      animations.animateLeft('animate-left');

    }

  }, [])

  return (
    <StyledOfferDescription className="container">
      <Section className="row reorder-sm-column">
        <div className="col-md-6 animate-left">
          <SectionTitle title="Badanie dorosłych"/>
          {/*unordered list*/}
          <div ref={rightDescription}
               dangerouslySetInnerHTML={{ __html: context.appointmentDescription.adults }}>
          </div>
        </div>
        <div className="col-md-6">
          <ImageWrapper ref={imagesRight}>
            <ImageRight alt="people" fluid={people1.childImageSharp.fluid}/>
            <ImageRightBackground alt="rectangle" fluid={peopleBckg1.childImageSharp.fluid}/>
          </ImageWrapper>
        </div>
      </Section>
      <div className="row">
        <div className="col-md-6">
          <ImageWrapper ref={imagesLeft}>
            <ImageLeft alt="people" fluid={child1.childImageSharp.fluid}/>
            <ImageLeftBackground alt="rectangle" fluid={childBckg1.childImageSharp.fluid}/>
          </ImageWrapper>
        </div>
        <div className="col-md-6 animate-right">
          <SectionTitle title="Badanie dzieci"/>
          <OrderedList ref={rightDescription}
                       className="bolded-counter"
                       dangerouslySetInnerHTML={{ __html: context.appointmentDescription.children }}>
          </OrderedList>
        </div>
      </div>
    </StyledOfferDescription>
  )
}


export const query = graphql`
    query {
        people: file(relativePath: {eq: "people-1.png"}) {
            relativePath
            childImageSharp {
                fluid(maxWidth: 527) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        peopleBckg: file(relativePath: {eq: "people-bckg.png"}) {
            relativePath
            childImageSharp {
                fluid(maxWidth: 527) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        child: file(relativePath: {eq: "child-1.png"}) {
            relativePath
            childImageSharp {
                fluid(maxWidth: 527) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        childBckg: file(relativePath: {eq: "child-bckg.png"}) {
            relativePath
            childImageSharp {
                fluid(maxWidth: 527) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
export default OfferDescription