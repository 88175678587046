import React, { useContext, useEffect } from "react"
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import { StyledOnlineRegistration, RegistrationComponent } from "./OnlineRegistration.styles"
import { ContentfulContext } from "../../contexts/ContentfulContext"

export const OnlineRegistration = () => {
  const context = useContext(ContentfulContext)

  useEffect(() => {
    console.log("Loaded")
    setTimeout(() => {
      console.log("loaded2")
    }, 0)
    if (typeof window !== "undefined") {
      window.onload = function() {
        console.log("loaded2!")
      }
    }

  }, [])

  return (
    <StyledOnlineRegistration className="container" id="online-registration">
      <SectionTitle title="Rejestracja online"/>
      {context && context.dataLoaded &&
        <RegistrationComponent>
          <iframe src="https://e-rejestracja.mp.pl/Tickets/w/8fdd08f0-8d9c-40b3-b6a4-a3668c95fa4b" height="512"
                  frameBorder="0" style={{ border: "0", width: "100%" }} allowFullScreen></iframe>
        </RegistrationComponent>
      }
    </StyledOnlineRegistration>
  )
}

export default OnlineRegistration