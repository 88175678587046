import styled from "styled-components";
import {colors} from "../../assets/contants/colors";
import sectionDecor from '../../assets/images/section-decor.svg';

export const Title = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: ${colors.default};
  position: relative;
  margin-left: 20px;
  
  &:before {
    content: ' ';
    background-image: url(${sectionDecor});
    display:block;
    width: 64px;
    height: 64px;
    position: absolute;
    left: -15px;
    top: -5px;
    z-index: -1;
  }
}
`