import React, { useContext, useEffect } from "react"
import {StyledOffer, Title, List, Info, InfoIcon, InfoTitle, InfoDescription} from "./Offer.styles"
import exlaminationMarkIcon from '../../assets/images/exclamination.png'
import { ContentfulContext } from "../../contexts/ContentfulContext"
import Animations from "../../helpers/animations"
export const Offer = () => {


  useEffect(() => {
    const animations = new Animations();
    animations.animateTop('animate-top');
    console.log("animations", animations)

  }, []);

  const context = useContext(ContentfulContext)
  const offer = context.offer.offer;
  const forPatient = context.offer.forPatient;

  const offerLength = offer && offer.length;
    let offerLeft = [];
    let offerRight = [];
  if(offerLength) {
    const half = Math.ceil(offerLength / 2);
    offerLeft = offer.slice(0 , half);
    offerRight = offer.slice(half, offerLength);
  }

  return (
    <StyledOffer className="bck-orange" id="offer">
      <div className="container">
        <Title>Oferta badania</Title>
        <div className="row">
          <div className="col-md-6">
            <List>
              {offerLeft.map((offer, index) => <li key={index}>{offer}</li>)}
            </List>
          </div>
          <div className="col-md-6">
            <List>
              {offerRight.map((offer, index) => <li key={index}>{offer}</li>)}
            </List>
          </div>
        </div>
        <Info className="animate-top">
          <InfoIcon className="flex-shrink-0">
            <img src={exlaminationMarkIcon} alt="exclamination-mark"/>
          </InfoIcon>
          <div>
            <InfoTitle>Dla pacjenta</InfoTitle>
            <InfoDescription dangerouslySetInnerHTML={{ __html: forPatient}} />
          </div>
        </Info>
      </div>
    </StyledOffer>

  )
}

export default Offer