import styled from "styled-components"

export const StyledOnlineRegistration = styled.section`
    padding-top: 80px;
    height: 840px;
`

export const RegistrationComponent = styled.div`
    margin-top: 37px;
    max-height: 512px;
    overflow: hidden;
}`