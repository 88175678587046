import React from "react";
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import {StyledImage, ImageWrapper} from "./Gallery.styles"
import {Heading, StyledGallery, CustomDot } from "./Gallery.styles"

import image1 from "../../assets/images/gallery/1/img.jpeg";
import thumb1 from "../../assets/images/gallery/1/thumb.png";

import image2 from "../../assets/images/gallery/2/img.jpeg";
import thumb2 from "../../assets/images/gallery/2/thumb.png";

import image3 from "../../assets/images/gallery/3/img.jpeg";
import thumb3 from "../../assets/images/gallery/3/thumb.png";

import image4 from "../../assets/images/gallery/4/img.jpeg";
import thumb4 from "../../assets/images/gallery/4/thumb.png";

import image5 from "../../assets/images/gallery/5/img.jpeg";
import thumb5 from "../../assets/images/gallery/5/thumb.png";

import image6 from "../../assets/images/gallery/6/img.jpeg";
import thumb6 from "../../assets/images/gallery/6/thumb.png";

export default class Gallery extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      images: [
        {
          image: image1,
          thumbnail: thumb1
        },
        {
          image: image2,
          thumbnail: thumb2
        },
        {
          image: image3,
          thumbnail: thumb3
        },
        {
          image: image4,
          thumbnail: thumb4
        },
        {
          image: image5,
          thumbnail: thumb5
        },
        {
          image: image6,
          thumbnail: thumb6
        }
      ],
      currentImage: null,
      currentImageIndex: null,
      activeSlide: 0
    }

  }
  getSliderSettings(){
    return {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesPerRow: 4,
      rows: 2,
      lazyLoad: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      beforeChange: (prev, next) => {
        this.setState({ activeSlide: next });
      },
      customPaging:  (i) => {
        return (
          <CustomDot className={`${this.state.activeSlide === i ? 'active' : ''}`}></CustomDot>
        );
      },
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            rows: 2,
            slidesToShow: 1,
            slidesPerRow: 2,
            centerPadding: 0,
            centerMode: true,
            infinite: true,
            dots: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            rows: 1,
            slidesToShow: 1,
            slidesPerRow: 1,
            centerPadding: 0,
            infinite: true,
            dots: true,
          }
        },
      ]

    }
  }

  handleClickImage = (e, image, index) => {
    e && e.preventDefault();

    this.setState({
      isOpen: true,
      currentImage: image.image,
      currentImageIndex: index
    })
  }

  handleCloseModal = (e) => {
    console.log("close")
    e && e.preventDefault();

    this.setState({
      currentImage: null,
      isOpen: false
    });
  }

  render(){
    const settings = this.getSliderSettings();
    const { images, currentImage } = this.state;
    return (
      <StyledGallery id="gallery">
        <div className="container p-lg-0">
          <Heading>Galeria</Heading>
          <Slider {...settings}>
            { images.map((image, index) => (
              <ImageWrapper key={index}>
                <StyledImage src={image.thumbnail} onClick={ e => this.handleClickImage(e, image, index)} alt={`gallery-${index}`}/>
              </ImageWrapper>
            ))}
          </Slider>
          {currentImage &&
          <Lightbox
            mainSrc={this.state.images[this.state.currentImageIndex].image}
            nextSrc={this.state.images[(this.state.currentImageIndex + 1) % this.state.images.length].image}
            prevSrc={this.state.images[(this.state.currentImageIndex + this.state.images.length - 1) % this.state.images.length].image}
            onMovePrevRequest={() =>
              this.setState({
                currentImageIndex: (this.state.currentImageIndex + this.state.images.length - 1) % this.state.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentImageIndex: (this.state.currentImageIndex + 1) % this.state.images.length,
              })
            }
            onCloseRequest={this.handleCloseModal}
          />
          }
        </div>
      </StyledGallery>
    )
  }
}
