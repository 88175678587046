import styled from "styled-components"
import { colors } from "../../assets/contants/colors"
import { breakpoints } from "../../assets/contants/breakpoints"

export const StyledOffer = styled.section`
  //min-height: 100px;
`

export const Title = styled.h2`
    padding-top: 57px;
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: bold;
`

export const List = styled.ul`
    font-size: 16px;
    font-weight: 500;
    margin-left: 20px;
    li {
      line-height: 2.63;
    }
`

export const Info = styled.div`
    background: ${colors.blue};
    padding: 30px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    display: flex;
    align-items: center;
    position: relative;
    top: 95px;
    margin-top: -45px;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        text-align: center;
    }
`

export const InfoIcon = styled.div`
    width: 130px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    margin-right: 30px;
    @media (max-width: ${breakpoints.sm}) {
        margin-right: 0;
    }
`

export const InfoTitle = styled.p`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: -8px;
    @media (max-width: ${breakpoints.sm}) {
        margin-top: 10px;
    }
`

export const InfoDescription = styled.div`
    font-size: 18px;
`
