import styled from "styled-components";
import {colors} from "../../assets/contants/colors"

export const StyledGallery = styled.div`
    background: ${colors.orange};
    padding-bottom: 110px;
    scroll-padding-top: 50px;
`
export const ImageWrapper = styled.div`
    outline: none;
    max-width: 272px;
    max-height: 272px;
    margin-right: 30px;
    margin-bottom: 30px;    
    &:last-child {
       margin-right: 0;
    }
`
export const Heading = styled.h2`
    padding: 50px 0;
    text-align: center;
    font-size: 36px;
`

export const StyledImage = styled.img`
    border-radius: 0 40px 0 40px;
    //margin: 0 auto 30px;
    cursor: pointer;
`

export const CustomDot = styled.div`
    width: 20px;
    height: 20px;
    background: ${colors.white};
    border-radius: 50%;
    
    &.active {
    background: ${colors.default}
    }
`