import styled from "styled-components";
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import { breakpoints } from "../../assets/contants/breakpoints"

export const StyledLocation = styled.section`
    min-height: 480px;
    scroll-padding-top: 50px;
`

export const LocationWrapper = styled.div`
    margin: 70px 0;
`

export const StyledSectionTitle = styled(SectionTitle)`
    margin-top: 30px;
`

export const LocationDescription = styled.div`
    font-size: 18px;
    margin-top: 28px;
    margin-left: 10px;
    max-width: 380px;
    height: 163px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
      @media (max-width: ${breakpoints.md}) {
    height: 125px;
  }
`

export const MapWrapper = styled.div`
  overflow: hidden;
  border-radius: 20px;

  iframe {
    width: 100%;
  }
  
  @media (max-width: ${breakpoints.md}) {
    margin-top: 20px;
  }
`

export const Address = styled.p`
    white-space: pre-wrap;
`