import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../assets/styles/main.scss";

import Navbar from "./Navbar/Navbar"
import Footer from "./Footer/Footer"
import { Helmet } from "react-helmet"
import faviconSmall from "../assets/images/favicon-small.png";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Specjalistyczny Gabinet Okulistyczny</title>
        <link rel="icon" href={faviconSmall} />
        <meta name="keywords" content="Okulista Tarnów, Gabinet okulistyczny, Skalska-Dziobek okulista, Okulista Wałowa"/>
      </Helmet>
      <Navbar siteTitle={data.site.siteMetadata.title} />
      <main className="main-content">{children}</main>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
