import styled from "styled-components"
import {SectionTitle} from "../../components/SectionTitle/SectionTitle"

export const StyledNews = styled.section`
    position: relative;
    height: 750px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`

export const StyledSectionTitle = styled(SectionTitle)`
    margin-top: 100px;
`

export const FacebookPagePlugin = styled.div`
    box-shadow: 0 13px 24px 0 rgba(0, 0, 0, 0.25);
    margin-top: 57px;
    margin-bottom: 110px;
    overflow: hidden;
`