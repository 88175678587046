import ScrollMagic from "scrollmagic";
import gsap from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

export default class Animations {

  constructor() {

    if(typeof window !== 'undefined') {
      ScrollMagicPluginGsap(ScrollMagic, gsap);

      this.scrollController = new ScrollMagic.Controller();

    }
  }

  animateLeft(className) {
    const elementsToAnimate = document.getElementsByClassName(className);
    gsap.set([elementsToAnimate], {autoAlpha: 0});
    Array.from(elementsToAnimate).forEach((element) => {
      const tl = gsap.timeline({defaults: {ease: 'power2.EaseInOut'}});
      tl.fromTo(element, {x: '-=100'}, {duration: 0.6, x: '0', autoAlpha: 1});
      new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: 0.6,
        reverse: false
      }).setTween(tl)
        .addTo(this.scrollController)
    })
  }

  animateRight(className) {
    const elementsToAnimate = document.getElementsByClassName(className);
    gsap.set([elementsToAnimate], {autoAlpha: 0});
    Array.from(elementsToAnimate).forEach((element) => {
      const tl = gsap.timeline({defaults: {ease: 'power2.EaseInOut'}});
      tl.fromTo(element, {x: '+=150'}, {duration: 0.6, x: '0', autoAlpha: 1});
      new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: 0.6,
        reverse: false
      }).setTween(tl)
        .addTo(this.scrollController)
    })
  }

  animateTop(className) {
    const elementsToAnimate = document.getElementsByClassName(className);
    gsap.set([elementsToAnimate], {autoAlpha: 0});
    Array.from(elementsToAnimate).forEach((element) => {
      const tl = gsap.timeline({defaults: {ease: 'power2.EaseInOut'}});
      tl.fromTo(element, {y: '-=50'}, {duration: 0.7, y: '0', autoAlpha: 1});
      new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: 0.6,
        reverse: false
      }).setTween(tl)
        .addTo(this.scrollController)
    })
  }
}