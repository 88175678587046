import React, { useContext } from "react"
import { StyledPricing, Heading, PricingList, Price } from "./Pricing.styles"
import { ContentfulContext } from "../../contexts/ContentfulContext"

export const Pricing = () => {

  const context = useContext(ContentfulContext)
  const pricing = context.pricing

  return (

    <StyledPricing id="pricing">
      <div className="container">
        <Heading>Cennik</Heading>
        <PricingList>
          {pricing.map((item, index) => <li key={index}>
            <span>{item.service}</span>
            <Price>{item.price } zł</Price>
          </li>)
          }
        </PricingList>
      </div>
    </StyledPricing>

  )
}

export default Pricing