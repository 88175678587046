import styled from "styled-components"
import { colors } from "../../assets/contants/colors"
import { breakpoints } from "../../assets/contants/breakpoints"
import Img from "gatsby-image"

export const StyledHeader = styled.div`
  min-height: 646px;
`

export const HeaderLeft = styled.div`
  height: 646px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.md}) {
      height: auto;
  }
  @media (max-width: ${breakpoints.sm}) {
      margin-top: 30px;
  }
`

export const Title = styled.p`
   font-size: 44px;
   font-weight: 800;
   @media (max-width: ${breakpoints.lg}) {
      font-size: 34px;
   }
`

export const Subtitle = styled.p`
  font-size: 31px;
  font-weight: normal;
  margin-top: 20px;
  @media (max-width: ${breakpoints.lg}) {
      font-size: 21px;
  }
`

export const ImageWrapper = styled.div`
position: relative;
 overflow: hidden;
 margin-top: 80px;
 @media (max-width: ${breakpoints.md}) {
      margin-top: 15px;
 }
`
export const ImageEye = styled(Img)`
  width: 100%;
  max-width: 527px;
`
export const ImageOverlay = styled(Img)`
  position: absolute !important;
 width: 100%;
 left: 0;
 top: 0;
 z-index: -1;
   max-width: 527px;
`


export const PhoneRegistration = styled.a`
  width: 205px;
  height: 38px;
  border-radius: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.blue};
  text-decoration: none;
  color: ${colors.default2}
`

export const OnlineRegistration = styled.div`
  width: 205px;
  height: 38px;
  border-radius: 15px;
    display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: ${colors.orange};
  cursor: pointer;
`
export const HeaderRight = styled.div`
   // justify-content: flex-end;
   // display: flex;
   //
   // @media (max-width: ${breakpoints.lg}) {
   //    justify-content: center;
   // }
   //
   // @media (max-width: ${breakpoints.sm}) {
   //    justify-content: flex-start;
   // }
`

export const ContactWrapper = styled.div`
    display: flex;
    align-items: center;
    
    span {
      margin: 0 20px;
    }
    
   @media (max-width: ${breakpoints.sm}) {
      flex-direction: column;
      div, a {
        width: 100%;
      };
   }
`

export const Info = styled.div`
    @media (max-width: ${breakpoints.sm}) {
      text-align: center;
   }
`