import React, { useContext, useEffect } from "react"
import {StyledPhoneRegistration, Heading, Phone, Details} from "./PhoneRegistration.styles"
import { ContentfulContext } from "../../contexts/ContentfulContext"

export const PhoneRegistration = () => {
  const context = useContext(ContentfulContext)
  return (
    <StyledPhoneRegistration id="contact">
      {context && context.contact && <div>
        <Heading>Rejestracja telefoniczna</Heading>
        <Phone href={'tel:' + context.contact.phoneNumber}>+{context.contact.phoneNumber}</Phone>
        <Details dangerouslySetInnerHTML={{ __html: context.contact.registration}}></Details>
      </div>}
    </StyledPhoneRegistration>
  )
}

export default PhoneRegistration

